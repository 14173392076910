var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isRow)?_c('div',{staticClass:"relative rounded-lg bg-white hover:text-gray-800 group text-gray-700 w-full flex gap-5 text-left hover:bg-gray-300 hover:bg-opacity-20 px-3 py-1.5 items-center border-b",on:{"click":function($event){return _vm.$emit('open', _vm.task.id)}}},[_c('div',{staticClass:"flex-1 flex flex-col gap-0.5"},[_c('div',{staticClass:"flex items-center gap-4"},[_c('fw-dot',{class:{ 'opacity-60': !_vm.isNew || !_vm.showAsWarning },attrs:{"inline":"","color":_vm.showAsWarning ? 'orange' : _vm.isNew ? 'primary' : 'light',"ping":_vm.isNew || _vm.showAsWarning}}),_c('v-clamp',{staticClass:"text-sm uppercase font-medium text-gray-500",attrs:{"autoresize":"","max-lines":1}},[_vm._v(" "+_vm._s(_vm._f("uppercase")(_vm.task.id))+" ")]),(_vm.showAsWarning)?_c('div',{staticClass:"text-xs text-yellow-600"},[_vm._v(" Aguarda resposta há "+_vm._s(_vm.daysDiff)+" dia"+_vm._s(_vm.daysDiff > 1 ? 's' : '')+" ")]):_vm._e()],1),_c('div',{staticClass:"ml-5 font-medium"},[_c('v-clamp',{staticClass:"leading-5",class:{ 'font-semibold': _vm.task.status === 'new' },attrs:{"autoresize":"","max-lines":1}},[_vm._v(" "+_vm._s(_vm.task.title)+" ")])],1)]),_c('div',{staticClass:"w-72"},[_c('fw-person',{attrs:{"person":_vm.user,"size":"xs","paddingless":""}})],1),_c('div',{staticClass:"flex-shrink-0 w-20"},[_c('fw-tag',{class:{
        'text-white': _vm.task.status == 'closed',
      },attrs:{"expanded":"","type":_vm.tagType[_vm.task.status]}},[_vm._v(_vm._s(_vm.$t(`status.${_vm.task.status}`)))])],1),_c('div',{staticClass:"text-sm text-gray-600 w-32"},[_vm._v(_vm._s(_vm._f("humanDateTimePT")(_vm.task.created_at)))])]):_c('div',{staticClass:"relative rounded-lg bg-white text-gray-700 group w-full flex flex-col text-left hover:bg-gray-300 hover:bg-opacity-20",class:{
    'px-3 py-2': _vm.isMinimal,
    'px-2 py-1.5': !_vm.isMinimal,
  },on:{"click":function($event){return _vm.$emit('open', _vm.task.id)}}},[_c('div',{staticClass:"flex flex-1 items-center justify-start gap-2 w-full"},[(!_vm.isMinimal)?_c('div',{staticClass:"flex-shrink-0"},[_c('fw-avatar',{attrs:{"user":_vm.user}})],1):_vm._e(),_c('div',{staticClass:"flex-1 flex flex-col gap-0.5"},[_c('div',{staticClass:"flex items-center justify-between text-gray-500 gap-1"},[_c('div',{staticClass:"font-medium text-xs uppercase"},[_c('v-clamp',{class:{ 'text-sm': !_vm.isMinimal, 'text-xs': _vm.isMinimal },attrs:{"autoresize":"","max-lines":1}},[_vm._v(" "+_vm._s(_vm._f("uppercase")(_vm.task.id))+" ")])],1),_c('div',{staticClass:"flex gap-3"},[_c('div',{staticClass:"text-xs text-gray-500"},[_vm._v(_vm._s(_vm._f("humanDateTimePT")(_vm.task.updated_at ?? _vm.task.created_at)))]),_c('div',{staticClass:"flex items-center"},[_c('fw-dot',{class:{ 'opacity-60': !_vm.isNew || !_vm.showAsWarning },attrs:{"inline":"","color":_vm.showAsWarning ? 'orange' : _vm.isNew ? 'primary' : 'light',"ping":_vm.isNew || _vm.showAsWarning}})],1)])]),_c('div',{staticClass:"flex-1 flex gap-2 items-end"},[_c('div',{staticClass:"flex-1 flex flex-col gap-1"},[_c('div',{staticClass:"font-medium"},[_c('v-clamp',{staticClass:"leading-5",class:{ 'font-semibold': _vm.task.status === 'new', 'h-11': _vm.isMinimal },attrs:{"autoresize":"","max-lines":_vm.isMinimal ? 2 : 1}},[_vm._v(" "+_vm._s(_vm.task.title)+" ")])],1),_c('div',{staticClass:"flex gap-2 justify-between"},[_c('div',[_c('fw-person',{attrs:{"person":_vm.user,"size":"2xs","paddingless":""}})],1),_c('div',{staticClass:"flex-shrink-0"},[_c('fw-tag',{class:{
                  'text-white': _vm.task.status == 'closed',
                },attrs:{"type":_vm.tagType[_vm.task.status],"size":"xs"}},[_vm._v(_vm._s(_vm.$t(`status.${_vm.task.status}`)))])],1)])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }