export const TASK_STATUS_TAG = {
  new: 'primary',
  open: 'light-primary',
  canceled: 'light',
  blocked: 'light-danger',
  closed: 'light',
  deleted: 'light',
  archived: 'light',
  review: 'light-orange',
  paused: 'medium',
}

export const TASK_STATUS_BUTTONS = {
  new: 'primary',
  open: 'light-primary',
  canceled: 'light',
  blocked: 'light-danger',
  closed: 'light',
  deleted: 'light',
  archived: 'light',
  review: 'light-orange',
  paused: 'light',
}
