<template>
  <div
    v-if="isRow"
    class="relative rounded-lg bg-white hover:text-gray-800 group text-gray-700 w-full flex gap-5 text-left hover:bg-gray-300 hover:bg-opacity-20 px-3 py-1.5 items-center border-b"
    @click="$emit('open', task.id)"
  >
    <div class="flex-1 flex flex-col gap-0.5">
      <div class="flex items-center gap-4">
        <fw-dot
          inline
          :color="showAsWarning ? 'orange' : isNew ? 'primary' : 'light'"
          :ping="isNew || showAsWarning"
          :class="{ 'opacity-60': !isNew || !showAsWarning }"
        />
        <v-clamp autoresize :max-lines="1" class="text-sm uppercase font-medium text-gray-500">
          {{ task.id | uppercase }}
        </v-clamp>
        <div v-if="showAsWarning" class="text-xs text-yellow-600">
          Aguarda resposta há {{ daysDiff }} dia{{ daysDiff > 1 ? 's' : '' }}
        </div>
      </div>
      <div class="ml-5 font-medium">
        <v-clamp autoresize :max-lines="1" class="leading-5" :class="{ 'font-semibold': task.status === 'new' }">
          {{ task.title }}
        </v-clamp>
      </div>
    </div>
    <div class="w-72">
      <fw-person :person="user" size="xs" paddingless />
    </div>
    <div class="flex-shrink-0 w-20">
      <fw-tag
        expanded
        :type="tagType[task.status]"
        :class="{
          'text-white': task.status == 'closed',
        }"
        >{{ $t(`status.${task.status}`) }}</fw-tag
      >
    </div>
    <div class="text-sm text-gray-600 w-32">{{ task.created_at | humanDateTimePT }}</div>
  </div>
  <div
    v-else
    class="relative rounded-lg bg-white text-gray-700 group w-full flex flex-col text-left hover:bg-gray-300 hover:bg-opacity-20"
    :class="{
      'px-3 py-2': isMinimal,
      'px-2 py-1.5': !isMinimal,
    }"
    @click="$emit('open', task.id)"
  >
    <div class="flex flex-1 items-center justify-start gap-2 w-full">
      <div v-if="!isMinimal" class="flex-shrink-0">
        <fw-avatar :user="user" />
      </div>
      <div class="flex-1 flex flex-col gap-0.5">
        <div class="flex items-center justify-between text-gray-500 gap-1">
          <div class="font-medium text-xs uppercase">
            <v-clamp autoresize :max-lines="1" :class="{ 'text-sm': !isMinimal, 'text-xs': isMinimal }">
              {{ task.id | uppercase }}
            </v-clamp>
          </div>
          <div class="flex gap-3">
            <div class="text-xs text-gray-500">{{ task.updated_at ?? task.created_at | humanDateTimePT }}</div>
            <div class="flex items-center">
              <fw-dot
                inline
                :color="showAsWarning ? 'orange' : isNew ? 'primary' : 'light'"
                :ping="isNew || showAsWarning"
                :class="{ 'opacity-60': !isNew || !showAsWarning }"
              />
            </div>
          </div>
        </div>
        <div class="flex-1 flex gap-2 items-end">
          <div class="flex-1 flex flex-col gap-1">
            <div class="font-medium">
              <v-clamp
                autoresize
                :max-lines="isMinimal ? 2 : 1"
                class="leading-5"
                :class="{ 'font-semibold': task.status === 'new', 'h-11': isMinimal }"
              >
                {{ task.title }}
              </v-clamp>
            </div>
            <div class="flex gap-2 justify-between">
              <div>
                <fw-person :person="user" size="2xs" paddingless />
              </div>
              <div class="flex-shrink-0">
                <fw-tag
                  :type="tagType[task.status]"
                  size="xs"
                  :class="{
                    'text-white': task.status == 'closed',
                  }"
                  >{{ $t(`status.${task.status}`) }}</fw-tag
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TASK_STATUS_TAG } from '@/utils/index.js'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    task: {
      type: Object,
      default: () => {},
    },
    user: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: 'normal',
    },
  },

  data() {
    return {
      tagType: TASK_STATUS_TAG,
    }
  },

  computed: {
    loggedUser() {
      return this.$store.getters.getUser
    },
    isMinimal() {
      return this.type === 'minimal'
    },
    isRow() {
      return this.type === 'row'
    },
    isNew() {
      return this.task.status === 'new'
    },
    daysDiff() {
      if (this.task.status != 'new' && this.task.status != 'open') return 0
      if (!this.task.last_manager_update) return Dates.now().diff(Dates.build(this.task.created_at), 'days')
      return Dates.now().diff(Dates.build(this.task.last_manager_update), 'days')
    },
    showAsWarning() {
      return this.daysDiff >= 2 && (this.task.status === 'new' || this.task.status === 'open')
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "status": {
      "label": "Estado",
      "new": "Nova",
      "open": "Aberta",
      "canceled": "Cancelada",
      "blocked": "Bloqueada",
      "closed": "Fechada",
      "deleted": "Apagada",
      "archived": "Arquivada"
    }
  },
  "en": {
    "status": {
      "label": "Status",
      "new": "New",
      "open": "Open",
      "canceled": "Canceled",
      "blocked": "Blocked",
      "closed": "Closed",
      "deleted": "Deleted",
      "archived": "Deprecated"
    }
  }
}
</i18n>
