<template>
  <fw-layout mobile-ready management :back-to="backTo">
    <template #main-content>
      <PanelTasks />
    </template>
  </fw-layout>
</template>

<script>
import PanelTasks from '@/components/panels/PanelTasks'

export default {
  components: {
    PanelTasks,
  },

  computed: {
    spaceKey() {
      return this.$route.params.key
    },

    queueKey() {
      return this.$route.params.queueKey
    },

    backTo() {
      return this.queueKey
        ? `/manage/spaces/${this.spaceKey}/queues/${this.spaceKey}`
        : `/manage/spaces/${this.spaceKey}`
    },
  },
}
</script>
