<template>
  <div class="h-full flex flex-col">
    <div class="px-4 pt-2 pb-4">
      <div class="flex gap-5 justify-between items-center">
        <div class="flex px-2">
          <fw-button size="md" :type="'tab-active'" :counter="tasks.length" :counter-total="totalResults">
            Tarefas
          </fw-button>
        </div>
        <div class="flex gap-5 items-center">
          <div>
            <ContextualSearch
              v-if="showSearch"
              type="minimal"
              :loading="loading"
              :filter-options="filters"
              :applied-filters="appliedFilters"
              :applied-sort="orderByValue"
              :applied-sort-direction="orderDirection"
              :multifilter="multifilter"
              :start-value="searchInput"
              :start-period="startPeriod"
              :end-period="endPeriod"
              :order-by-options="orderBy"
              :show-time-period="true"
              :time-period-label="$t('createdDate')"
              :can-close="activeModal === null"
              :show-user-picker="true"
              :available-users-keys="managersKeys"
              :users="users"
              :applied-users="userFilters"
              :user-picker-label="$t('assignees')"
              @sort-order-changed="sortOrderChanged"
              @search="search"
            >
              <template v-if="false" #tags>
                <FilterTag
                  v-for="(userKey, u) in userFilters"
                  :key="'filter_user_' + userKey"
                  :text="users[userKey].name ?? 'Sem nome'"
                  :show-close-button="true"
                  @close="deleteUserFilter(u)"
                ></FilterTag>
                <FilterTag
                  v-for="(filter, f) in appliedFilters"
                  :key="'filter_' + f"
                  :text="getFilterText(filter)"
                  :show-close-button="true"
                  @close="deleteFilter(f)"
                ></FilterTag>
                <FilterTag
                  v-if="startPeriod != null && endPeriod != null"
                  :text="startPeriod + ' - ' + endPeriod"
                  :show-close-button="true"
                  @close="deleteDates()"
                ></FilterTag>
              </template>
            </ContextualSearch>
          </div>
          <div v-if="false">
            <div>
              <fw-button type="xlight">Criar tarefa</fw-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div ref="availableSpace" class="flex-1 px-4 overflow-auto">
      <LoadingPlaceholder v-if="loading" size="lg" />
      <RecycleScroller
        v-else-if="!loading"
        v-slot="{ item }"
        key-field="key"
        :items="tasks"
        :item-size="48"
        :buffer="50"
      >
        <router-link
          :to="{ name: 'manage-space-queue-task', params: { key: spaceKey, queueKey: queueKey, taskId: item.id } }"
        >
          <RecordTask type="row" :task="item" :user="users[item.user_key]" />
        </router-link>
      </RecycleScroller>
    </div>
    <div v-if="totalPages > 1" class="px-4">
      <BlockPagination
        v-if="totalPages > 1 && !loading"
        :per-page="limit"
        :total="totalResults"
        :total-pages="totalPages"
        :current.sync="page"
        @page-changed="pageChanged"
      />
    </div>
  </div>
</template>

<script>
import { RecycleScroller } from 'vue-virtual-scroller'
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch'
import FilterTag from '@/fw-modules/fw-core-vue/ui/components/text/FilterTag.vue'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import RecordTask from '@/components/records/RecordTask'

import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  components: {
    ContextualSearch,
    RecycleScroller,
    FilterTag,
    BlockPagination,
    LoadingPlaceholder,
    RecordTask,
  },

  props: {
    showFilters: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    multifilter: {
      type: Boolean,
      default: false,
    },
    maxNumberSpaces: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      filters: [
        {
          key: 'status',
          label: this.$t('status.label'),
          options: [
            {
              key: 'all',
              label: this.$t('all'),
            },
            {
              key: 'new',
              label: this.$t('status.new'),
            },
            {
              key: 'open',
              label: this.$t('status.open'),
            },
            // {
            //   key: 'canceled',
            //   label: this.$t('status.canceled'),
            // },
            // {
            //   key: 'blocked',
            //   label: this.$t('status.blocked'),
            // },
            {
              key: 'closed',
              label: this.$t('status.closed'),
            },
          ],
        },
        {
          key: 'stale',
          label: this.$t('stale'),
          options: [
            {
              key: 'all',
              label: this.$t('all'),
            },
            {
              key: '2', // 2 days
              label: this.$t('stale'),
            },
          ],
        },
      ],

      orderBy: [
        {
          key: 'id',
          label: this.$t('orderBy.taskId'),
          type: 'string',
        },
        {
          key: 'title',
          label: this.$t('orderBy.title'),
          type: 'string',
        },
        {
          key: 'status',
          label: this.$t('orderBy.taskStatus'),
          type: 'string',
        },
        {
          key: 'created_at',
          label: this.$t('orderBy.createdDate'),
          type: 'date',
        },
        {
          key: 'last_manager_update',
          label: this.$t('orderBy.updatedDate'),
          type: 'date',
        },
      ],
      page: 1,
      totalResults: 0,
      totalPages: 1,
      limit: 25,
      tasks: [],
      users: {},
      loading: true,
      searchInput: '',
      orderByValue: 'last_manager_update',
      orderDirection: 'DESC',
      appliedFilters: [],
      managersKeys: [],
      userFilters: [],
      startPeriod: null,
      endPeriod: null,
      activeModal: null,
    }
  },

  computed: {
    queueKey() {
      return this.$route.params.queueKey
    },

    spaceKey() {
      return this.$route.params.key
    },

    api() {
      return this.$store.state.api.base
    },
  },

  watch: {
    queueKey(newVal) {
      if (newVal) {
        this.page = 1
        this.totalResults = 0
        this.totalPages = 1
        this.limit = 25
        this.tasks = []
        this.users = {}
        this.loading = true
        this.searchInput = ''
        this.orderByValue = 'created_at'
        this.orderDirection = 'DESC'
        this.appliedFilters = []
        this.startPeriod = null
        this.endPeriod = null
        this.getTasks()
      }
    },
  },

  mounted() {
    utils.sleep(100).then(() => {
      this.getUrlParams()
      this.getTasks()
    })
  },

  methods: {
    taskUrl(spaceKey, queueKey, taskId) {
      return this.$router.resolve({
        name: 'manage-space-queue-task',
        params: { key: spaceKey, queueKey: queueKey, taskId: taskId },
      }).href
    },

    deleteFilter(index) {
      this.appliedFilters.splice(index, 1)
      this.setUrlParams()
      this.getTasks()
    },

    deleteUserFilter(index) {
      this.userFilters.splice(index, 1)
      this.setUrlParams()
      this.getTasks()
    },

    deleteDates() {
      this.startPeriod = null
      this.endPeriod = null
      this.setUrlParams()
      this.getTasks()
    },

    getFilterText(key) {
      return utils.getFilterText(key, this.filters)
    },

    search(data) {
      this.appliedFilters = JSON.parse(JSON.stringify(data.filters))
      this.searchInput = data.term

      if (data.orderBy != null) {
        this.orderByValue = data.orderBy
        this.orderDirection = data.orderDirection
      }

      this.startPeriod = data.dates.length == 2 ? this.$options.filters.formatDate(data.dates[0]) : null
      this.endPeriod = data.dates.length == 2 ? this.$options.filters.formatDate(data.dates[1]) : null

      this.userFilters = data.filterUsers

      this.$emit('searching')

      this.setUrlParams()
      this.getTasks()
    },

    getUrlParams() {
      if (this.$route.query.q) {
        this.searchInput = this.$route.query.q
      }

      if (this.$route.query.f) {
        this.appliedFilters = this.$route.query.f.split(',')
      }

      if (this.$route.query.s) {
        this.orderByValue = this.$route.query.s
        this.orderDirection =
          this.$route.query.o == 'ASC' || this.$route.query.o == 'DESC' ? this.$route.query.o : 'none'
      }

      if (this.$route.query.p) {
        this.page = parseInt(this.$route.query.p)
      }

      if (this.$route.query.start) {
        this.startPeriod = this.$route.query.start
      }

      if (this.$route.query.end) {
        this.endPeriod = this.$route.query.end
      }
    },

    setUrlParams() {
      let query = {}
      if (this.searchInput.length > 0) {
        query['query'] = this.searchInput
      }

      if (this.appliedFilters.length > 0) {
        query['f'] = this.appliedFilters.join(',')
      }

      if (this.orderByValue.length > 0) {
        query['s'] = this.orderByValue
        query['o'] = this.orderDirection
      }

      if (this.startPeriod != null && this.endPeriod != null) {
        query['start'] = this.startPeriod
        query['end'] = this.endPeriod
      }

      query['p'] = this.page

      this.$router.push({ path: this.$route.path, query: query })
    },

    sortOrderChanged(newSort) {
      if (newSort != null && newSort.key != null) {
        this.orderByValue = newSort.key.key
        this.orderDirection = newSort.direction
      }
      this.setUrlParams()
      this.getTasks()
    },

    async getTasks() {
      this.loading = true
      const maxNumber = this.maxNumberSpaces != null ? this.maxNumberSpaces : this.limit
      const query = {
        limit: maxNumber,
        page: this.page,
        ...utils.setFiltersQuery(this.appliedFilters, false),
      }

      if (query.status == 'all') delete query.status
      if (query.stale == 'all') delete query.stale

      if (this.orderByValue.length > 0) {
        query['sort'] = this.orderByValue
        query['direction'] = this.orderDirection.toLowerCase()
      }

      if (this.searchInput.length > 0) {
        query['query'] = this.searchInput
      }

      if (this.startPeriod && this.endPeriod) {
        query['created_start'] = this.parseDatesForPayload(this.startPeriod)
        query['created_end'] = this.parseDatesForPayload(this.endPeriod)
      }

      if (this.userFilters.length > 0) {
        query['managers'] = this.userFilters
      }

      try {
        const result = await this.api.getTasks(this.spaceKey, this.queueKey, query)
        this.totalResults = result.tasks.pagination?.total_items
        this.totalPages = result.tasks.pagination?.total_pages
        this.page = result.tasks.pagination?.current_page
        this.tasks = result.tasks.tasks
        this.users = result.users
        this.managersKeys = result.tasks.filters['managers[]']
      } catch (error) {
        console.error('getTasks Error: ', error)
      }

      this.loading = false
    },

    pageChanged(page) {
      console.log('pageChanged to :>> ', page)
      if (page) this.page = page
      this.setUrlParams()
      this.getTasks()
    },

    parseDatesForPayload(formatedDate) {
      if (formatedDate) {
        return Dates.from(formatedDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
      }
      return formatedDate
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "assignees": "Responsáveis",
    "stale": "Pendentes",
    "createdDate": "Data de criação",
    "results": "Resultados",
    "tasks": "Tarefas",
    "noTasks": "Não foram encontrados registos para apresentar.",
    "status": {
      "label": "Estado",
      "new": "Novo",
      "open": "Aberto",
      "canceled": "Cancelado",
      "blocked": "Bloqueado",
      "closed": "Fechado",
      "deleted": "Apagado",
      "archived": "Arquivado"
    },
    "all": "Todos",
    "orderBy": {
      "taskId": "Identificador do pedido",
      "taskStatus": "Estado do pedido",
      "createdDate": "Data de criação",
      "updatedDate": "Data de última atualização",
      "title": "Título do pedido"
    }
  },
  "en": {
    "assignees": "Assignees",
    "createdDate": "Creation date",
    "results": "Results",
    "tasks": "Tasks",
    "noTasks": "No tasks to present.",
    "status": {
      "label": "Status",
      "new": "New",
      "open": "Open",
      "canceled": "Canceled",
      "blocked": "Blocked",
      "closed": "Closed",
      "deleted": "Deleted",
      "archived": "Deprecated"
    },
    "all": "All",
    "stale": "Stale",
    "orderBy": {
      "taskId": "Task identifier",
      "taskStatus": "Task status",
      "createdDate": "Creation date",
      "updatedDate": "Data de última atualização",
      "title": "Task title"
    }
  }
}
</i18n>
